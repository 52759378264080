const kr={
	qfh:'THB',
    jiantizhongwen:'대한민국',
    xianlu:'선',
    dianwei:'포인트',
    wodezichan:'내 자산',
    touzikuaixun:'투자 뉴스',
    jiaoyiyonghu:'사용자',
    jiaoyipinglei:'범주',
    danlunhuoli:'이익',
    jiaoyiriqi:'날짜',
    dengluhouchakan:'로그인 후 보기',
    shouye:'집',
    jiaoyidating:'거래 플로어',
	jiaoyidatingabc:'거래 플로어',
    yucun:'대주',
    zoushi:'경향',
    wode:'내 거',
    zuiditouzi:'최소 투자',
    zuidijinru:'최소 입장 인원',
    jichulicai:'기본 자금 조달',
    zishenlicai:'상위 자금 조달',
    dashilicai:'마스터 파이낸싱',
    zhuanjialicai:'전문가 자금 조달',
	基础理财:'기본 자금 조달',
    资深理财:'상위 자금 조달',
    大师理财:'마스터 파이낸싱',
    专家理财:'전문가 자금 조달',
    huiyuanquanyi:'U-id',
	huiyuanname:'U-name',
    zuoriyingli:'어제 이익',
    zongzichan:'총 자산',
    chucunjin:'돈',
    jinriyingli:'오늘의 이익',
    zhuanchu:'철회하다',
    cunru:'보증금',
    zhanghuxinxni:'계정 정보',
    lishizhangdan:'역사적 법안',
    zijinminxi:'자금 세부 정보',
    renwuzhongxin:'미션센터',
    xiaoxigonggao:'뉴스 발표',
    tuijianfenxiang:'공유를 권장합니다',
    huodongzhongxin:'액티비티 센터',
    jibenziliao:'기본 정보',
    dengluzhanghao:'로그인 계정',
    nicheng:'별명',
    shezhi:'설정',
    genggaixianlu:'줄 바꾸기',
    guanyuwomen:'우리에 대하여',
    xitongshezhi:'시스템 설정',
    yuyanshezhi:'언어 설정',
    tuichudenglu:'로그아웃',
    bangdingyinhangka:'은행카드 바인딩',
    bangdingxuniqianbao:'가상 지갑 바인딩',
    anquanma:'보안 코드',
    xiugailenglumima:'로그인 비밀번호 수정',
    yinhangkaguanli:'은행카드 관리',
    tianjiayinhangka:'은행카드 추가',
    xuniqianbaoguanli:'가상 지갑 관리',
    tianjiaxuniqianbao:'가상 지갑 추가',
    chiakren:'카드 소지자',
    yinhangkakahao:'은행 카드 번호',
    yinhangmingcheng:'은행 이름',
    yhkTps:'카드 소유자 본인의 은행카드를 등록해 주세요.',
    nixiqianbaodizhi:'지갑 주소',
    xuniqianbaoleixing:'지갑 유형',
    xnqbTps:' 알림: trc20, erc20, omni와 같이 USDT 주소 유형을 입력하세요. ',
    plchikaren:'카드 소유자를 입력해주세요',
    plkahao:'카드 소유자 카드 번호를 입력하세요.',
    plyinhangmingcheng:'은행 이름을 입력하세요',
    plqbdz:'가상 지갑 주소를 입력해주세요.',
    plqblx:'가상 지갑 유형을 입력하세요.',
    shezhianquanma:'보안코드 설정',
    yuananquanma:'원래 보안 코드',
    xinanquanma:'새로운 보안 코드',
    planquanma:'원래 보안코드를 입력해주세요.',
    plxinanquanma:'새로운 보안 코드를 입력하세요',
    queren:'확인하다',
	quxiao:'취소',
    anquanmaTps:'은행카드 비밀번호와 다른 보안코드를 설정해주세요.',
    anquanmaTpsTow:'고객님께, 이 보안 코드는 송금 시 비밀번호입니다. 보안상의 이유로 로그인 비밀번호와 동일하지 않도록 주의해 주시기 바랍니다.',
    xiugaidenglu:'로그인 비밀번호 수정',
    jiumima:'이전 비밀번호',
    xinmima:'새로운 비밀번호',
    querenmima:'비밀번호 확인',
    pljiumima:'이전 비밀번호를 입력하세요',
    plxinmima:'새로운 비밀번호를 입력해주세요.',
    plquerenmima:'비밀번호를 다시 입력해주세요',
    wancheng:'완벽한',
    kaishitouzi:'투자를 시작하세요',
    kaiTps:'현재 회선을 사용할 수 없는 경우 다른 회선으로 전환해 보세요.',
    zuiyou:'최적',
    dangaqianxianlu:'현재 줄',
    dangqianbanben:'현재 버전',
    banquansuoyou:'저작권',
    yinglizhendong:'수익 충격',
    gensuixitong:'기본',
    zhuanchujine:'양',
    plzhuanchu:'이체금액을 입력해주세요.',
    zhuanchuTps:'팁: 문의사항이 있으시면 플랫폼 고객 서비스에 문의해주세요.',
    xuanzeyh:'출금하실 은행카드를 선택해주세요.',
    xuanzehb:'출금할 가상지갑을 선택해주세요.',
    tijiaoshenqing:'신청서 제출',
    shuruanquanma:'보안코드를 입력해주세요',
    xianshangcunru:'온라인 입금',
    saomacunru:'USDT',
    wangyincunru:"은행입금",
    shijian:'시간',
    upshijian:'시작 시간',
    downshijian:'종료 시간',
    wuTps:'데이터없음 ~',
    jiazaicx:'다시 로드',
    di:'기간 ',
    lun:' ',
    weiyingli:'무익한',
    yishouli:'수락됨',
    zhuangtai:'상태',
    chongzhi:'다시 놓기',
    riqi:'날짜',
    CapActive:{
        chongzhi:'재충전',
        tixian:'철회하다',
        goumai:'구입하다',
        yingli:'이익',
        zengsong:'주다',
        weitongguo:'통과하지 못했습니다',
        yitongguo:'통과',
        yijujue:'거부됨',
    },
    zhanneixiaoxi:"정보",
    pingtaigonggao:'발표',
    fuzhilanjie:"링크 복사",
    denglu:'로그인',
    zhuce:'등록하다',
    jizhumima:'기억하다',
    wangjimima:'비밀번호를 잊으셨나요?',
    youkedenglu:'방문자 로그인',
    zhucezhanghao:"계정 등록",
    plusername:'사용자 이름을 입력하세요',
    plpassword:'비밀번호를 입력해주세요',
    wanchengzhuce:'완벽한',
    yiyouzhanghao:'계정이 있으세요',
    qingdenglu:'로그인해주세요',
    remenhuati:'핫 토픽',
    news1:'원래의',
    news2:'추천하다',
    news3:'유산',
    renliulan:'먹다',
    hezhi:'그리고 가치',
    lunshu:'기간',

    做多:'INR',
    做空:'KRW',
    多单:'CAD',
    多双:'AUD',
    空单:'USD',
    空双:'UER',
    平单:'SGD',
    平双:'GBP',
    极阴:'JPY',
    极阳:'THB',
    qi:'',
    juli:'거리',
    lunjiezhi:'끝',
    yifengpan:'베팅을 중단하세요',
    yiguanbi:'닫은',
    yonghu:'사용자',
    danlunjiaoyi:'거래',
    caozuo:'작동하다',
    pljoin:'채팅방에 성공적으로 참여했습니다',
    pltingshou:'제품이 단종되었습니다.',
    gengou:"따르다",
	quedinggengou:"구매를 진행하시겠습니까?",
	wanjia:"플레이어",
	leibie:"범주",
	jine:"양",
    gouru:'구입하다',
    zanweigouru:'선택하시고 여기를 클릭해서 구매해주세요',
    qigou:'구입',
    gourushuliang:'구매수량',
    changgui:'전통적인',
    shuzi:'숫자',
    yinglihezhi:'이익과 가치',
    shouyijieshao:'소득',
    lijicunru:'보증금',
    kaihaojieguo:'기록',
    kaihaoqushi:'경향',
    chanpingshuoming:'설명',
    qingchuhuancun:'캐시 지우기',
    youke:'방문객',
	gongxi:'축하합니다',
	zai:'에서',
	yingli:'이익',
	xiadanchenggong:'성공적으로 주문했습니다',
	jiazaizhong:'로딩중...',
	guanbitg:'닫힘, 구매 중단',
	xiangoudl:'현재 라운드만 따르세요',
	liaotiantip:'시스템이 금지되었습니다. 구매를 선택해주세요.',
	tishi:'팁',
	zhidao:'좋아요',
	zhuanchusb:'전송에 실패했습니다',
	zhuanchusbs:'잔액이 부족하여 이체에 실패했습니다.',
	buyxq:'구매 세부 정보',
	orderNo:'법안 번호',
	plan_name:'홀 정보',
	buyxx:'구매 정보',
	haoxx:'번호선택 세부정보',
	buysj:'시간을 사다',
	yilou: '없어진',
	fzjqb: '클립보드에 복사됨',
	chanpin1: '판매 시간: 3.5분마다 한 라운드가 진행되며, 같은 날 오전 10시에 시작하여 다음 날 오전 2시에 마감됩니다.',
	chanpin2: '각 라운드에는 0에서 9까지의 숫자가 3개 있습니다. 3개 숫자의 합이 최종 결과입니다. 결과에 투자할 수 있습니다: Many, Short, Flat Single, Flat Double, Many Single, Many Double, Short Single, Short Double, Cathode, Anode, 그리고 number.',
	Tips:{
        wjmm: '비밀번호를 잊으셨다면, 고객센터로 연락해 비밀번호를 변경하세요!',
        dlcg: '로그인 성공',
        tjcg: '성공적으로 제출되었습니다',
        zanwu: '데이터 없음',
        buyizhi: '두 비밀번호가 일치하지 않습니다',
        zcbz: '귀하의 자산이 부족합니다. 입금해 주세요.',
        pltxje: '출금금액을 입력해주세요.',
        zdtx: '최소 출금금액 100',
		youke: '관광객 여러분: 정회원으로 등록하시고 운영해주시기 바랍니다.',
		szcg: '성공적으로 설정되었습니다',
     },
	smrz:'실명인증',
	smrzzm: '인증 긍정적',
	smrzxm:'이름',
	smrzhm:'신분증 번호',
	smrztip: '성공적으로 제출되었습니다. 검토를 기다리고 있습니다!',
	qwszl: '정보를 완성해주세요',
	kfa:'1위 고객 서비스',
	kfb:'2번째 고객 서비스',
	kfc:'No.3 고객서비스',
	aboutaa:'Morgan Stanley는 1935년 창립 이래로 일류의 사업을 일류 방식으로 꾸준히 제공해 왔습니다. 우리가 하는 모든 일의 기반에는 5가지 핵심 가치가 있습니다. Morgan Stanley의 리더십은 일류의 사업을 일류 방식으로 수행하는 데 전념합니다. 이사회와 고위 임원진은 자본이 사회 전체에 이익이 될 수 있고 그래야 한다는 믿음을 가지고 있습니다.',
	aboutab:'Morgan Stanley에서 하는 모든 일은 5가지 핵심 가치에 따라 이루어집니다. 옳은 일을 하고, 고객을 우선시하고, 뛰어난 아이디어로 이끌고, 다양성과 포용성을 위해 노력하고, 보답합니다. Morgan Stanley는 다양한 팀의 역량으로 차별화됩니다. 접근성과 포용성 문화는 유산을 쌓고 미래를 형성하여 사업을 강화하고 고객에게 가치를 제공하는 데 도움이 됩니다.',
	aboutac:'Morgan Stanley에서는 고객이 최우선입니다. 개인, 가족, 기관 및 정부가 목표를 달성하는 데 필요한 자본을 조달, 관리 및 분배하도록 돕습니다. Morgan Stanley를 탐색하세요. 사람, 기업 및 기관이 부를 축적, 보존 및 관리하여 재정적 목표를 추구할 수 있도록 돕습니다. Morgan Stanley에서는 뛰어난 아이디어로 선두를 달립니다. 모든 사업에서 오늘날 가장 중요한 문제에 대한 예리한 통찰력을 제공합니다.',
	aboutad:'우리가 하는 일',
	
	xzyouxishuoming:'투자방향을 선택해주세요.',
	kefu:'고객 서비스',
	meiyouzhanghao:'계정이 없으신가요?',
	shifangshuaxin:'아래로 당겨서 새로 고침하세요.',
	xialashuaxin:'아래로 당겨서 새로 고침하세요.',
	tijiaoanniu: '보내다',
	
	womenshishui: '우리는 누구인가?',
	wmjs: '우리의 장점은 글로벌 역량과 현지 시장에 대한 심층적인 지식을 결합하는 것입니다.',
	wmjsa: '우리는 전 세계에 투자 전문가 팀을 배치하여 귀하의 투자 포트폴리오를 확보합니다. 물론, 우리는 상황에 적응하여 유리한 단기 투자 기회를 먼저 잡을 수도 있습니다.',
	wmjsc: 'UBS 투자 사무소(CIO)가 전략을 수립합니다.',
	wmjsb: '고객을 위한 투자 기회를 찾아보세요',
	tohome: '홈페이지로 이동',
	huancun: '페이지 새로 고침',/*刷新页面*/
	wfcw: '잔액이 부족합니다. 충전해주세요',/*餘額不足，請儲值*/
	dccw: '현재는 닫혀 있어 구매가 불가능합니다.',/*目前已關閉且無法購買*/
	dzcw: '아직 처리되지 않은 주문이 있는 경우 고객센터로 문의해주세요.',/*有未处理订单，请联系客服*/
	dwcw: '출금 가능한 잔액이 부족합니다', /*余额不足，无法提款*/
	dxcw: '펀드 비밀번호 오류',/*资金密码错误*/
	gongsijieshao: '에 대한',
	fk: '서비스',
}
export default kr;