import { createI18n } from 'vue-i18n'
import th from './config/th'
// import yn from './config/yn'
import en from './config/en'
// import hk from './config/hk'
import kr from './config/kr'
// import jp from './config/jp'
// import de from './config/de'
import es from './config/es'
console.log(createI18n)
let languageset = navigator.language
let language = 'en'
if(languageset == 'en' || languageset == 'en-US' || languageset == 'en-GB'){
	language = 'en';
}
// if(languageset == 'de'){
// 	language = 'de';
// }
if(languageset == 'es' || languageset == 'es-MX'){
	language = 'es';
}
// if(languageset == 'zh-TW'){
// 	language = 'hk';
// }
// if(languageset == 'ja'){
// 	language = 'jp';
// }
if(languageset == 'ko' || languageset == 'ko-KR'){
	language = 'kr';
}
if(languageset == 'th' || languageset == 'th-TH'){
	language = 'th';
}
// if(languageset == 'vi' || languageset == 'vi-VN'){
// 	language = 'yn';
// }
const i18n  = createI18n({
    legacy: false, // composition API
    globalInjection:true,
    locale:localStorage.getItem('locale')|| language,
    messages:{
        th,
        // yn,
		en,
		// hk,
		kr,
		// jp,
		// de,
		es
    },
 })
 export default i18n;